.loader {
  width: 100%;
  height: 5px;
  margin: 0 auto;
  border: 0px solid transparent;
  position: relative;
  padding: 1px;
}
.loader:before {
  content: "";
  border: 1px solid transparent;
  border-radius: 40px;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}
.loader .loaderBar {
  position: absolute;
  border-radius: 90px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #4dd637;
  width: 0;
  height:5px;
  animation: borealisBar 1s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}